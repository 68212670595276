import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import DateUtils from '@/shared/utils/DateUtils';
import { URLS } from '@/shared/backend';

const logger = new Logger('pdf-export');
const documentFilesExportModule = namespace('documentFilesExport');

@Component({
  components: {},
})
export default class PdfExportView extends Vue {
  @documentFilesExportModule.Action('getDocumentFilesExports')
  private actionGetDocumentFilesExports!: any;
  @documentFilesExportModule.Getter('getDocumentFilesExports')
  private getDocumentFilesExports!: any;

  @documentFilesExportModule.Action('markAsDownloadedPdfExport')
  private actionMarkAsDownloadedPdfExport!: any;

  private exports: any = {
    searchParams: {},
  };
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('number_of_docs'), value: 'filesCount' },
      { text: this.$t('status'), value: 'status' },
      { text: this.$t('create_date'), value: 'createdAt' },
      {
        text: this.$t('download'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  onClickDownload(item: any) {
    this.openFile(item.fileId, item.fileName, item.id);
  }

  created() {
    this.actionGetDocumentFilesExports().then(() => {});
  }

  dateISOToLocal(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale, false, true);
  }

  private openFile(fileId: string, shortFilename: string, id: string) {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${fileId}`;
    this.download(fileUrl, shortFilename);
    this.actionMarkAsDownloadedPdfExport(id).then(() => {
      this.actionGetDocumentFilesExports();
    });
  }

  private download(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob: Blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  getDocumentFilesExportsOnOptionsUpdate(searchOptions: any) {
    this.$nextTick(() => {
      this.getDocumentFilesExports.searchParams.dataOption = searchOptions;
      this.actionGetDocumentFilesExports();
    });
  }
}
